import { t } from "@lingui/macro";
import { Separator } from "@reactive-resume/ui";

import { Copyright } from "@/client/components/copyright";
import { LocaleSwitch } from "@/client/components/locale-switch";
import { Logo } from "@/client/components/logo";
import { ThemeSwitch } from "@/client/components/theme-switch";

export const LayoutFooter = () => (
  <footer className="" style={{ backgroundColor: "white", color: "black" ,borderTop:'1px solid #ADADAD' }}>
    {/* <Separator /> */}

    <div className="py-12" style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',padding:'40px 62px'}}>
      <div className="mt-3">© 2023 Make CV Online - Best Drag & Drop Resume Builder</div>

     
        <div className="flex items-center justify-end mt-3">
          <div>Privacy Policy</div>
          <div style={{marginLeft:'25px'}}>Terms & Conditions</div>
        </div>
      </div>
   
  </footer>
);
