import { Fragment } from "react";
import { t } from "@lingui/macro";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Logo } from "@/client/components/logo";
import { motion } from "framer-motion";
import { ProductHuntBanner } from "./product-hunt-banner";
import { useLogout } from "@/client/services/auth";
import { useAuthStore } from "@/client/stores/auth";
import { Button } from "@reactive-resume/ui";
import { SignOut } from "@phosphor-icons/react";

const navigation = [
  { name: "All Templates", href: "#", current: false }, // current true if active tab
  { name: "Blog", href: "#", current: false },
];

const mobileNavigationIfLogin = [
  { name: "All Templates", href: "#", current: false }, // current true if active tab
  { name: "Blog", href: "#", current: false },
  { name: "LogOut", href: "#", current: false },
];

const mobileNavigationIfLogOut = [
  { name: "All Templates", href: "#", current: false }, // current true if active tab
  { name: "Blog", href: "#", current: false },
  { name: "LogIn", href: "#", current: false },
  { name: "SignUp", href: "#", current: false },

];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const Header = () => {
  const { logout } = useLogout();
  const isLoggedIn = useAuthStore((state) => !!state.user);
  const userData = useAuthStore((state) => state.user);

  console.log("isLoggedIn", isLoggedIn);
  console.log("userData", userData);

  return (
    <motion.header //fixed
      className="fixed inset-x-0 top-0 z-20"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0, transition: { delay: 0.3, duration: 0.3 } }}
    >
      <ProductHuntBanner />
      <Disclosure as="nav" className="bg-white">
        {({ open }: any) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start ">
                  <div className="flex flex-shrink-0 items-center">
                    <Link to="/" className=" w-100">
                      <Logo size={91} />
                    </Link>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? "text-blue hover:text-white" : "header-nav-btn",
                            "br-30 px-3 py-2 text-sm font-medium",
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* <button
                    type="button"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  {/* Profile dropdown */}
                  {/* <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>

                         <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        /> 
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700",
                              )}
                            >
                              Your Profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700",
                              )}
                            >
                              Settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700",
                              )}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu> */}
                  <div className="hidden sm:ml-6 sm:block">
                    {!isLoggedIn ? (
                      <div className="flex space-x-4">
                        <a
                          className={"header-nav-btn br-30 px-3 py-2 text-sm font-medium"}
                        >
                          <Link to="/auth/login">{`Login`}</Link>
                        </a>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-5 br-30">
                          <Link to="/auth/register">{`Sign Up`}</Link>
                        </button>
                      </div>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center", color: "black" }}>
                        <Button
                          size="lg"
                          variant="link"
                          onClick={() => logout()}
                          className="mr-5"
                          style={{ color: "black" }}
                        >
                          <SignOut className="mr-3" />
                          {t`Logout`}
                        </Button>
                        <Button asChild size="lg" variant={"link"} style={{ color: "black" }}>
                          <Link to="/dashboard">{t`Go to Dashboard`}</Link>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div
                className="space-y-1 px-2 pb-3 pt-2"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {isLoggedIn
                  ? mobileNavigationIfLogin.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current ? "text-blue hover:text-white" : "header-nav-btn",
                          "br-30 px-3 py-2 text-sm font-medium",
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))
                  : mobileNavigationIfLogOut.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current ? "text-blue hover:text-white" : "header-nav-btn",
                          "br-30 px-3 py-2 text-sm font-medium",
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </motion.header>
  );
};
