import { t } from "@lingui/macro";
import "./styles.css";
import { Trans } from "@lingui/react";
//import { ArrowRight } from "@phosphor-icons/react";

import {
  Copy,
  Database,
  EnvelopeSimple,
  EnvelopeSimpleOpen,
  Robot,
  ShareNetwork,
  SkipBack,
  SkipForward,
  StarHalf,
} from "@phosphor-icons/react";
import { PencilSimpleLine } from "@phosphor-icons/react";
import { HandPointing } from "@phosphor-icons/react";
import { Brain } from "@phosphor-icons/react";
import { FloppyDisk } from "@phosphor-icons/react";
import { Badge, Input, buttonVariants } from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";
import { motion } from "framer-motion";
import Tilt from "react-parallax-tilt";

import { defaultTiltProps } from "@/client/constants/parallax-tilt";

import { HeroCTA } from "./call-to-action";
import { Decoration } from "./decoration";
import studyGirlImg from "../../../../assets/images/studentStudying.png";
import templeteImg from "../../../../assets/images/HeroTemplete.png";
import openAiImg from "../../../../assets/images/openAi.png";
import resume1Img from "../../../../assets/images/resume1.png";
import resume2Img from "../../../../assets/images/resume2.png";
import resume3Img from "../../../../assets/images/resume3.png";
import { useState } from "react";

const aiFeatures = [
  {
    logo: <PencilSimpleLine className="text-white text-5xl" />,
    title: "Make details according to your work",
    Description:
      "by using AI in this feature, you will easily make details of your work. help you to get professional words used by applicants from well-known companies,",
  },
  {
    logo: <Brain className="text-white text-5xl" />,
    title: "Easy to use with one click",
    Description:
      "with one click you will get convenience and AI will process the work according to your request",
  },
  {
    logo: <HandPointing className="text-white text-5xl" />,
    title: "The system works automatically",
    Description:
      "the artificial intelligence system will read and understand your commands quickly with 87% accuracy",
  },

  {
    logo: <FloppyDisk className="text-white text-5xl" />,
    title: "Use the templates provided by us",
    Description:
      "by using the template recommendations that are already available by us based on the templates used by applicants from big companies",
  },
];

const aiFeatures2 = [
  {
    logo: <PencilSimpleLine className="text-white text-5xl" />,
    title: "Online Resume Builder",
    Description: "The only drag and drop resume builder helps you to create your resume in minutes",
  },
  {
    logo: <FloppyDisk className="text-white text-5xl" />,
    title: "Professional Templates",
    Description:
      "Our professional resume and cover letter templates will help you stand out in the competition",
  },
  {
    logo: <EnvelopeSimpleOpen className="text-white text-5xl" />,
    title: "Cover Letter Builder",
    Description: "Make your cover letters look same like your resume by using the modern templates",
  },

  {
    logo: <ShareNetwork className="text-white text-5xl" />,
    title: "Share Document Online",
    Description:
      "After you finish, you can share your resume and cover letter with our unique link",
  },
  {
    logo: <Database className="text-white text-5xl" />,
    title: "Unlimited Storage",
    Description:
      "Save your documents in our storage. We will keep them private, encrypted and save",
  },
  {
    logo: <Copy className="text-white text-5xl" />,
    title: "Copy and Paste Text",
    Description: "Get inspired or copy the phrases and texts from our database of jobs",
  },
];

export const HeroSection = () => {
  const translatedHeading1 = `Automate your CV creation with <br/>our AI resume builder.`;
  const translatedDescription1 = `Some people simply don’t enjoy writing resumes as much <br/>as we do.That’s okay. Our AI Resume Writer is for you.`;
  const translatedHeading2 = `Why you should use artificial intelligence features to make it easier for you`;
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);

  // Data for accordion items
  const accordionData = [
    {
      title: " What is Kickresume AI Resume Writer?",
      content:
        "Make CV AI Resume Writer is based on OpenAI’s GPT-3 neural network which is a powerful language model able to produce texts that are indistinguishable from texts written by human writers. In other words, the AI Resume Writer does exactly what you’d think it would — automatically writes your resume.",
    },
    {
      title: "Is the AI Resume Writer free?",
      content:
        "Make CV AI Resume Writer is based on OpenAI’s GPT-3 neural network which is a powerful language model able to produce texts that are indistinguishable from texts written by human writers. In other words, the AI Resume Writer does exactly what you’d think it would — automatically writes your resume.",
    },
    {
      title: "How does the AI Resume Writer work?",
      content:
        "Make CV AI Resume Writer is based on OpenAI’s GPT-3 neural network which is a powerful language model able to produce texts that are indistinguishable from texts written by human writers. In other words, the AI Resume Writer does exactly what you’d think it would — automatically writes your resume.",
    },
    {
      title: "How do I make an AI resume?",
      content:
        "Make CV AI Resume Writer is based on OpenAI’s GPT-3 neural network which is a powerful language model able to produce texts that are indistinguishable from texts written by human writers. In other words, the AI Resume Writer does exactly what you’d think it would — automatically writes your resume.",
    },
    {
      title: "How to make a resume in AI?",
      content:
        "Make CV AI Resume Writer is based on OpenAI’s GPT-3 neural network which is a powerful language model able to produce texts that are indistinguishable from texts written by human writers. In other words, the AI Resume Writer does exactly what you’d think it would — automatically writes your resume.",
    },
  ];

  // Function to toggle active accordion item
  const toggleAccordion2 = (index: number) => {
    setActiveAccordion((prev) => (prev === index ? null : index));
  };
  return (
    <div
      className="container"
      style={{ background: "white", padding: "150px 20px", color: "black" }}
    >
      <div className="">
        <div
          className="text-base font-bold tracking-wide text-center"
          style={{ color: "#666", fontSize: "32px", marginBottom: "20px" }}
        >{`AI Resume Writer`}</div>
        <h1
          className="text-3xl font-bold tracking-tight sm:text-6xl text-center text-gray-800 heading1-text"
          style={{ color: "#3E3D3D", fontFamily: "Literata", marginBottom: "35px" }}
        >
          <div dangerouslySetInnerHTML={{ __html: translatedHeading1 }} />
        </h1>

        <p
          className="ml-6 text-sm text-center"
          style={{ color: "#666", fontSize: "24px", lineHeight: "32px", marginBottom: "36px" }}
        >
          <div dangerouslySetInnerHTML={{ __html: translatedDescription1 }} />
        </p>
      </div>
      <div className="flex justify-center" style={{ margin: "50px 0" }}>
        <img src={templeteImg} />
      </div>
      <div>
        <div
          style={{
            color: "#3E3D3D",
            fontFamily: "Literata",
            marginBottom: "35px",
            fontSize: "34px",
            lineHeight: "normal",
            fontWeight: "800",
            textAlign: "center",
          }}
          dangerouslySetInnerHTML={{ __html: translatedHeading2 }}
        />
      </div>
      <div className="mt-10 mb-20">
        <div className="md:flex" style={{ flexWrap: "wrap" }}>
          {aiFeatures.map((aiFeature, i) => {
            return (
              <div key={i} className="md:w-1/2 single-feature-mp">
                <div className="" style={{ display: "flex", color: "red" }}>
                  <div
                    style={{
                      background: "#2F8FFF",
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "12px",
                      marginRight: "10px",
                      padding: "10px",
                    }}
                  >
                    {aiFeature.logo}
                  </div>
                  <div>
                    <div
                      style={{
                        color: "#3E3D3D",
                        fontFamily: "Literata",
                        fontWeight: "800",
                        fontSize: "24px",
                        fontStyle: "normal",
                        lineHeight: "normal",
                      }}
                    >
                      {aiFeature.title}
                    </div>
                    <div
                      style={{
                        color: "#666",
                        fontFamily: "Be Vietnam",
                        fontWeight: "400",
                      }}
                    >
                      {aiFeature.Description}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="hero-cr-container">
        <div className="left-cr-cont">
          <div
            className="font-bold tracking-tight sm:text-6xl text-gray-800"
            style={{
              color: "#3E3D3D",
              fontFamily: "Literata",
              marginBottom: "15px",
              fontSize: "34px",
              lineHeight: "normal",
            }}
          >
            Create Your Resume Here With AI
          </div>
          <div
            style={{
              color: "#666",
              fontFamily: "Be Vietnam",
              fontWeight: "400",
            }}
          >
            Use AI to build your resume quickly and save your time with <br /> available resume
            templates
          </div>
          <div className="md:flex">
            <div className="pl-0 p-3" style={{ width: "17rem" }}>
              <Input
                style={{ border: "1px solid #b1b1b1", borderRadius: "15px", height: "43px" }}
                pattern="[a-z0-9]{10}"
                placeholder="Your"
                title={t`Backup Codes may contain only lowercase letters or numbers, and must be exactly 10 characters.`}
                // {...field}
              />
            </div>
            <div className="pl-0 p-3" style={{ width: "17rem" }}>
              <Input
                style={{ border: "1px solid #b1b1b1", borderRadius: "15px", height: "43px" }}
                pattern="[a-z0-9]{10}"
                placeholder="Your"
                title={t`Backup Codes may contain only lowercase letters or numbers, and must be exactly 10 characters.`}
                // {...field}
              />
            </div>
          </div>
          <div className=" mt-4">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-3xl mx-3">
              Generate Resume with AI
            </button>
          </div>
        </div>
        <div className="cr-right-img-container">
          <div className="img-css-container">
            <img className="image-with-gradient" src={studyGirlImg} alt="Your Image" />
            <div className="overlay"></div>
          </div>
        </div>
      </div>
      <div className="powered-by-container">
        <div className="md:flex" style={{ flexWrap: "wrap" }}>
          <div className="md:w-1/2">
            <img src={openAiImg} alt="" />
          </div>
          <div className="md:w-1/2">
            <div className="flex items-center justify-between mt-8">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-xl mx-1">
                AI Resume Builder
              </button>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-xl mx-1">
                Automatic Resume Writer
              </button>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-xl mx-1">
                AI Cover Letter
              </button>
            </div>
            <div className=" items-center justify-center  w-full mt-4">
              <h1
                className=""
                style={{
                  fontFamily: "Literata",
                  fontSize: "36px",
                  fontWeight: "800",
                  color: "#3E3D3D",
                  lineHeight: "55px",
                }}
              >
                Powered by OpenAI's GPT-4 NLP model.
              </h1>

              <p
                className=""
                style={{
                  fontFamily: "Be Vietnam",
                  fontSize: "18px",
                  fontWeight: "200",
                  lineHeight: "29px",
                  color: "#666666",
                }}
              >
                {" "}
                GPT-4 is an AI language model so powerful, it was at first deemed too dangerous to
                unleash on the world. Now you can use it to write your resume. And while that may
                seem like an overkill, GPT-4 is uniquely well-suited for generating structured
                documents such as resumes. Try it for free and see the results for yourself.
              </p>
            </div>
            <div className=" mt-4">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl  mx-3">
                Try AI Resume Writer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className="md:flex text-black mt-12 "
          // style={{ flexWrap: "wrap" }}
        >
          <div className="md:w-1/3 p-1">
            <img src={resume1Img} alt="" />
          </div>
          <div className="md:w-1/3 p-1">
            <img src={resume2Img} alt="" />
          </div>
          <div className="md:w-1/3 p-1">
            <img src={resume3Img} alt="" />
          </div>
        </div>
      </div>
      <div className=" css-my">
        <div className="md:flex" style={{ alignItems: "center", justifyContent: "space-around" }}>
          <div className="md:w-1/2">
            <div
              className="font-bold tracking-tight sm:text-6xl text-gray-800"
              style={{
                color: "#3E3D3D",
                fontFamily: "Literata",
                marginBottom: "15px",
                fontSize: "34px",
                lineHeight: "normal",
              }}
            >
              AI-Generated Resume Examples
            </div>
            <div
              style={{
                color: "#666",
                fontFamily: "Be Vietnam",
                fontWeight: "400",
              }}
            >
              What does an AI-generated resume look like? See for yourself! These resumes were
              generated by our AI resume builder.
            </div>
          </div>
          <div className="md:w-1/2">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
              <button className="bg-blue-500  blue-outline text-white font-bold py-2 px-4 rounded-xl mx-1">
                <SkipBack size={20} />
              </button>
              <button className="bg-blue-500  blue-outline text-white font-bold py-2 px-4 rounded-xl mx-1">
                <SkipForward size={20} />
              </button>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl mx-1">
                More
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* reviewed starts */}
      <div
        className=""
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "100px",
          padding: "0 60px",
          marginBottom: "20px",
        }}
      >
        <div className="" style={{ flexWrap: "wrap" }}>
          <div
            className="font-bold tracking-tight sm:text-4xl text-gray-800"
            style={{
              color: "#3E3D3D",
              fontFamily: "Literata",
              fontSize: "24px",
              lineHeight: "normal",
            }}
          >
            Reviewed by the community. <br />
            Trusted by Professional
          </div>
        </div>
        <div className="" style={{ display: "flex", justifyContent: "end", padding: "20px 0" }}>
          <div className="bg-blue-500 text-white font-bold py-1 px-3 rounded-xl">
            <div style={{ color: "white" }}>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "23px",
                  fontWeight: "800px",
                  fontFamily: "Literata",
                }}
              >
                4.5
              </div>
              <div style={{ fontSize: "10px" }}>
                <span>Out of </span>
                <span>5</span>
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: "10px" }}>
            <div className="flex items-center">
              {" "}
              {/* Use the 'items-center' class to vertically align the stars */}
              <span className="text-blue-500">&#9733;</span>
              <span className="text-blue-500">&#9733;</span>
              <span className="text-blue-500">&#9733;</span>
              <span className="text-blue-500">&#9733;</span>
              <span className="text-blue-500">&#9733;</span>
            </div>

            <div className="">
              <span style={{ fontSize: "9px" }}>Check MCV on Trustpilot</span>
            </div>
          </div>
        </div>
      </div>
      {/* cards starts */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "80px",
          flexWrap: "wrap",
        }}
      >
        <div
          className=""
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-around",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <div className="md:flex">
            <div className="rounded-lg overflow-hidden max-w-sm ">
              <div className="px-6 py-4 pb-0">
                <p
                  className="text-gray-700 text-base"
                  style={{ color: "#666", fontFamily: "Be Vietnam", fontWeight: "400" }}
                >
                  I have been looking for the site like <br />
                  this for a very long time. I am a very <br />
                  demanding person, but finally, I found <br /> a template suitable for my CV.
                </p>
              </div>
              <div style={{ fontSize: "25px" }} className="flex px-6 py-2">
                <span className="text-yellow-500">&#9733;</span>
                <span className="text-yellow-500">&#9733;</span>
                <span className="text-yellow-500">&#9733;</span>
                <span className="text-yellow-500">&#9733;</span>
                <span className="text-yellow-500">&#9733;</span>
              </div>
            </div>
          </div>

          <div className="md:flex">
            <div className="rounded-lg overflow-hidden max-w-sm ">
              <div className="px-6 py-2">
                <p
                  className="text-gray-700 text-base"
                  style={{ color: "#666", fontFamily: "Be Vietnam", fontWeight: "400" }}
                >
                  I would only recommend this site to <br /> all my friends.Amazing templates ,{" "}
                  <br /> user friendly environment and great <br /> possibilities
                </p>
              </div>
              <div style={{ fontSize: "25px" }} className="flex px-6">
                <span className="text-yellow-500">&#9733;</span>
                <span className="text-yellow-500">&#9733;</span>
                <span className="text-yellow-500">&#9733;</span>
                <span className="text-yellow-500">&#9733;</span>
                <span className="text-yellow-500">&#9733;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* cards ends */}
      {/* third card in center starts */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="rounded-lg overflow-hidden max-w-sm ">
          <div className="px-6 py-2">
            <p
              className="text-gray-700 text-base"
              style={{ color: "#666", fontFamily: "Be Vietnam", fontWeight: "400" }}
            >
              Very clear and simple CV making <br /> platform. Very good service
            </p>
          </div>
          <div style={{ fontSize: "25px" }} className="flex px-6">
            <span className="text-yellow-500">&#9733;</span>
            <span className="text-yellow-500">&#9733;</span>
            <span className="text-yellow-500">&#9733;</span>
            <span className="text-yellow-500">&#9733;</span>
            <span className="text-yellow-500">&#9733;</span>
          </div>
        </div>
      </div>
      {/* third card in center ends */}
      <div className="conatiner-padding-manage css-my">
        <div
          className="font-bold tracking-tight sm:text-2xl text-gray-800"
          style={{
            color: "#3E3D3D",
            fontFamily: "Literata",
            marginBottom: "15px",
            fontSize: "24px",
            lineHeight: "normal",
            textAlign: "center",
          }}
        >
          AI Resume Writer FAQ
        </div>
        <div className="md:flex" style={{ alignItems: "center", justifyContent: "center" }}>
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                color: "#666",
                fontFamily: "Be Vietnam",
                fontWeight: "400",
              }}
            >
              While we’ve tried to make this tool as easy to use as possible, it’s okay to have
              questions. If you don’t find your answers here, contact our customer support.
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-3xl mx-auto space-y-4" style={{ textAlign: "center" }}>
        {accordionData.map((item, index) => (
          <div key={index} className="">
            <div
              className={`p-4 cursor-pointer flex justify-between items-center ${
                activeAccordion === index ? "bg-gray-200" : ""
              }`}
              onClick={() => toggleAccordion2(index)}
            >
              <div
                style={{
                  color: "#3E3D3D",
                  fontFamily: "Literata",
                  fontSize: "24px",
                  fontWeight: "700",
                }}
              >
                {item.title}
              </div>
              <div>{activeAccordion === index ? "-" : "+"}</div>
            </div>
            {activeAccordion === index && (
              <div
                className="p-4 bg-gray-100"
                style={{
                  color: "#666",
                  fontFamily: "Be Vietnam",
                  fontWeight: "400",
                }}
              >
                {item.content}
              </div>
            )}
          </div>
        ))}

        <div>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl  mx-3 my-5">
            Show Less
          </button>
        </div>
      </div>
      <div
        className="font-bold tracking-tight sm:text-2xl text-gray-800"
        style={{
          color: "#3E3D3D",
          fontFamily: "Literata",
          marginTop: "60px",
          fontSize: "30px",
          lineHeight: "normal",
          textAlign: "center",
          whiteSpace: "pre-line",
        }}
      >
        All these additional features will help you throughout the journey
      </div>
      <div className="mt-10 mb-20">
        <div className="md:flex" style={{ flexWrap: "wrap" }}>
          {aiFeatures2.map((aiFeature2, i) => {
            return (
              <div key={i} className="md:w-1/3 single-feature-mp">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#2F8FFF",
                      width: "60px",
                      height: "60px",
                      borderRadius: "12px",
                      marginRight: "10px",
                      padding: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {aiFeature2.logo}
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "20px",
                        color: "black",
                        fontFamily: "Literata",
                        fontWeight: "800",
                        padding: "10px 0",
                        textAlign: "center",
                      }}
                    >
                      {aiFeature2.title}
                    </div>
                    <div
                      style={{
                        color: "#666",
                        fontFamily: "Be Vietnam",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      {aiFeature2.Description}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
